import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Data, Params } from '@angular/router';
import { ItemDataService } from '../../data.service';
import { ItemImageService } from '../../documentfile.service';
import firebase from 'firebase';
import { environment } from '../../../environments/environment.prod';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SITE_SETTINGS } from '../../settings';

@Component({
  selector: 'app-view-bitting-details',
  templateUrl: './view-bitting-details.component.html',
  styleUrls: ['./view-bitting-details.component.scss']
})
export class ViewBittingDetailsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  driverID;
  user;

  dirverAppForm;
  isUpdateSuccess;
  isUpdateFailure;

  bookingID;
  bittings = [];
  bookingDetails;
  showBitters = false;

  displayedColumns = ['name', 'phoneNumber', 'bitAmount', 'assignTrip'];
  dataSource: MatTableDataSource<any>;

  settings = SITE_SETTINGS
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  ngOnInit(): void {

    this.bookingID = this.route.snapshot.params.bookingID;


    firebase.database().ref(`websiteBookings/${this.bookingID}`).on("value", snap => {
      let data = snap.val();
      if (data?.bitters) {

        this.bookingDetails = data;

        Object.keys(data.bitters).forEach(bittingKey => {
          this.bittings.push(data.bitters[bittingKey])
        });

        this.dataSource = new MatTableDataSource(this.bittings);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);

        this.showBitters = true;

      } else {
        this.showBitters = false;
      }
    });


  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  assignTrip(driver) {

    firebase.database().ref(`drivers/${driver.uid}`).on("value", snap => {
      let driverData = snap.val();
      console.log(driverData);

      let tripDetails = {
        ...this.bookingDetails,
        assignedDriverDetails: driver,
        commissionAmmount: +((this.settings.commissionPercent / 100) * this.bookingDetails.estimate).toFixed(2)
      }

      let wallet = driverData.walletAmount - +((this.settings.commissionPercent / 100) * tripDetails.estimate);

      if(driverData.walletAmount >= tripDetails.commissionAmmount ) {

      let transactions = {
        date: new Date().toISOString(),
        deduction: +((this.settings.commissionPercent / 100) * tripDetails.estimate).toFixed(2),
        BookingId: this.bookingID,
        from: tripDetails.originCityName,
        to: tripDetails.destinationCityName
      }

      firebase.database().ref(`drivers/${driver.uid}`).update({ walletAmount: wallet.toFixed(2) }).then(res => {
        console.log('walletUpdated Successfully');
      });

      firebase.database().ref(`drivers/${driver.uid}/transactions/${this.bookingID}`).update(transactions).then(res => {
        console.log('Transactions Updated Successfully');
      });

      firebase.database().ref(`activeTrips/${this.bookingID}`).update(tripDetails).then(res => {

        console.log('Trip Assigned Successfully');

        firebase.database().ref(`websiteBookings/${this.bookingID}`).remove().then(res => {
          console.log('Trip Moved to Assigned Trip');
        });

      });

      } else {
        alert('Insufficient balance to assign this trip')
      }
    });
  }
}
