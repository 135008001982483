import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Data, Params } from '@angular/router';
import { ItemDataService } from '../../data.service';
import { ItemImageService } from '../../documentfile.service';
import firebase from 'firebase';
import { environment } from '../../../environments/environment.prod';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-driver-details',
  templateUrl: './view-driver-details.component.html',
  styleUrls: ['./view-driver-details.component.scss']
})
export class ViewDriverDetailsComponent implements OnInit {

  driverID;
  user;

  dirverAppForm;
  isUpdateSuccess;
  isUpdateFailure;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  ngOnInit(): void {

    this.driverID = this.route.snapshot.params.driver;

    this.dirverAppForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl(''),
      email: new FormControl('', [
        Validators.required
      ]),
      address: new FormControl('', [Validators.required]),
      walletAmount: new FormControl(0),
      addMoneyToWallet: new FormControl(0, [
        Validators.pattern('^[0-9]*$')
      ])
    });


    firebase.database().ref(`drivers/${this.driverID}`).on("value", snap => {
      let data = snap.val();
      if (data) {
        this.user = data;
        this.dirverAppForm.patchValue({
          name: this.replaceUndefinedOrNull(this.user.name),
          phoneNumber: this.replaceUndefinedOrNull(this.user.phoneNumber),
          email: this.replaceUndefinedOrNull(this.user.email),
          address: this.replaceUndefinedOrNull(this.user.address),
          walletAmount: this.replaceUndefinedOrNull(this.user.walletAmount),
        });
        console.log(this.user);
      }
    });
  }

  replaceUndefinedOrNull(v: any): string {
    if (v == undefined || v == null) {
      return '';
    }
    return v;
  }

  acctivateAccount() {
    if (this.dirverAppForm.invalid) {
      return
    }

    let user = {
      ...this.dirverAppForm.value,
      accountStatus: 'ACTIVE'
    }

    firebase.database().ref(`drivers/${this.driverID}`).update(user).then(res => {
      this.isUpdateSuccess = true;
      this.toastr.success('Account Activated Successfully');
    })
      .catch(() => {
        this.isUpdateFailure = true;
      });
  }

  deactivateAccount() {

    let user = {
      ...this.dirverAppForm.value,
      accountStatus: 'DEACTIVATED'
    }

    firebase.database().ref(`drivers/${this.driverID}`).update(user).then(res => {
      this.isUpdateSuccess = true;
      this.toastr.success('Account Deactivated Successfully');
    })
      .catch(() => {
        this.isUpdateFailure = true;
      });
  }

  addMoney() {
    if (!this.dirverAppForm.get('addMoneyToWallet').value) {
      this.toastr.error('Enter valid amount');
      return
    }

    let user = {
      ...this.dirverAppForm.value,
      walletAmount: +this.dirverAppForm.get('walletAmount').value + +this.dirverAppForm.get('addMoneyToWallet').value
    }

    firebase.database().ref(`drivers/${this.driverID}`).update(user).then(res => {
      this.isUpdateSuccess = true;
      this.dirverAppForm.patchValue({
        addMoneyToWallet: ''
      });

      this.toastr.success('Wallet money updated successfully');

    })
      .catch(() => {
        this.isUpdateFailure = true;
      });

  }

  onSubmit() { }

}
