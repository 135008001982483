import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { environment } from '../environments/environment';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      whitelistedCountries: ['in'],
      recaptchaParameters: {
        size: 'invisible'
      }
    }
  ],
  tosUrl: 'https://www.Nathantravels.com',
  privacyPolicyUrl: 'https://www.Nathantravels.com',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { AddEditDriversComponent } from './views/add-edit-drivers/add-edit-drivers.component';
import { DiversComponent } from './views/divers/divers.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ViewDriverDetailsComponent } from './views/view-driver-details/view-driver-details.component';
import { ViewBittingDetailsComponent } from './views/view-bitting-details/view-bitting-details.component';
import { ActiveTripsComponent } from './views/active-trips/active-trips.component';
import { AssginedTripsComponent } from './views/assgined-trips/assgined-trips.component';
import { CompletedTripsComponent } from './views/completed-trips/completed-trips.component';
import { ToastrModule } from 'ngx-toastr';
import { CompleteTripDetailPageComponent } from './views/completed-trips/complete-trip-detail-page/complete-trip-detail-page.component';
import { TariffModule } from './views/tariff/tariff.module';
import { AddBookingsComponent } from './views/add-bookings/add-bookings.component';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { map } from 'rxjs/operators';
import { NewBookingsComponent } from './views/confirmed-bookings/new-bookings.component';

export function agmConfigFactory(http: HttpClient, config: LazyMapsAPILoaderConfigLiteral) {
  const id = window.location.pathname.replace(/\//g, "");
  return () => http.get<any>(`https://us-central1-calvincare-emailservice.cloudfunctions.net/mapkey`).pipe(
    map(response => {
      config.apiKey = response['mapkey'];
      console.log(response['mapkey']);
      return response;
    })
  ).toPromise();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    AgmDirectionModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCjR1uag36CDQKA_8iNnc82k991jjb-0O8',
      libraries: ["places"]
    }),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    ToastrModule.forRoot(),
    TariffModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatStepperModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    AddEditDriversComponent,
    DiversComponent,
    ViewDriverDetailsComponent,
    ViewBittingDetailsComponent,
    ActiveTripsComponent,
    AssginedTripsComponent,
    CompletedTripsComponent,
    CompleteTripDetailPageComponent,
    AddBookingsComponent,
    NewBookingsComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [HttpClient, LAZY_MAPS_API_CONFIG],
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    IconSetService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
