export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyClqvXw92wftZvkCHcsePMfQmity-wEJZk",
    authDomain: "nathantravels-website.firebaseapp.com",
    databaseURL: "https://nathantravels-website-default-rtdb.firebaseio.com",
    projectId: "nathantravels-website",
    storageBucket: "nathantravels-website.appspot.com",
    messagingSenderId: "698692223990",
    appId: "1:698692223990:web:c1b80623f43913af86fdd7"
  },
  UNSPLASH_SOURCE_API:
    'https://source.unsplash.com/1280x900/?restaurants,food,alcohol',
};
