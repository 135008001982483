import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-update-tariff',
  templateUrl: './update-tariff.component.html',
  styleUrls: ['./update-tariff.component.scss']
})
export class UpdateTariffComponent implements OnInit {

  tariffFormGroup: FormGroup;
  submitted = false;
  tariffKey;
  tariff;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private route: ActivatedRoute, private router: Router
  ) { }

  ngOnInit(): void {

    this.tariffKey = this.route.snapshot.paramMap.get('car');

    this.tariffFormGroup = this.formBuilder.group({
      carType: ['', Validators.required],
      oneway: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      roundtrip: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      onewayDriverFee: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      roundTripDriverFee: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      availableCars: ['', Validators.required],
      passengers: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      luggage: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      image: ['', Validators.required],
      activeImage: ['', Validators.required],
      tariffImage: ['', Validators.required]
    });

    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }

    firebase.database().ref(`tariff/${this.tariffKey}`).on("value", snap => {
      let data = snap.val();

      if (data) {
        this.tariff = data;
        this.tariffFormGroup.setValue(this.tariff);
      }
    });
  }


  get tariffValidation(): { [key: string]: AbstractControl } {
    return this.tariffFormGroup.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.tariffFormGroup.invalid) {
      return;
    }

    firebase.database().ref(`tariff/${this.tariffKey}`).update(this.tariffFormGroup.value).then(res => {
      this.toastr.success('Tariff Updated Successfully');
      this.tariffFormGroup.reset();
      this.submitted = false;
      this.router.navigateByUrl('/tariff');
    });
  }

}
