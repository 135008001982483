export const SITE_SETTINGS = {
    siteName: 'Nathan Travles Admin',
    phoneNo: '',
    whatsapp: '',
    websiteAddress: 'www.nathantravels.com',
    pushNotificationAPI: 'https://us-central1-nathantravels-website.cloudfunctions.net/sendNotification/api/v1/sendNotification',
    isPushNotificationAPIEnabled: true,
    smsKey: '',
    enquiryEmail: 'jakkinath1234@gmail.com',
    emailAPI: 'https://us-central1-oceanic-glazing-309707.cloudfunctions.net/email-v1/sendEmail',
    smsAPI: 'https://us-central1-oceanic-glazing-309707.cloudfunctions.net/sms/api/v1/sendsms',
    tariffAPI: 'https://cdn.contentful.com/spaces/hsndvypgz5mf/environments/master/entries?access_token=e3lRweqcPnDNL2pn2sNzwOKmUL8z34kASll32yendKg',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Mobile Enquiry Email From',
    bannerColor: "#fdc500",
    appPassword: 'jmhdvgwgscnfifcc',
    appEmail: 'bookings.nathantravels@gmail.com',
    adminMobileNumber: ['+918122335555', '+917299568568', '+916379203286'],
    commissionPercent: 10,
}


export const TIME = [
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
]