import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { DiversComponent } from './views/divers/divers.component';
import { AddEditDriversComponent } from './views/add-edit-drivers/add-edit-drivers.component';
import { ViewDriverDetailsComponent } from './views/view-driver-details/view-driver-details.component';
import { ViewBittingDetailsComponent } from './views/view-bitting-details/view-bitting-details.component';
import { ActiveTripsComponent } from './views/active-trips/active-trips.component';
import { AssginedTripsComponent } from './views/assgined-trips/assgined-trips.component';
import { CompletedTripsComponent } from './views/completed-trips/completed-trips.component';
import { CompleteTripDetailPageComponent } from './views/completed-trips/complete-trip-detail-page/complete-trip-detail-page.component';
import { TariffListComponent } from './views/tariff/tariff-list/tariff-list.component';
import { UpdateTariffComponent } from './views/tariff/update-tariff/update-tariff.component';
import { AddTariffComponent } from './views/tariff/add-tariff/add-tariff.component';
import { AddBookingsComponent } from './views/add-bookings/add-bookings.component';
import { NewBookingsComponent } from './views/confirmed-bookings/new-bookings.component';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['dashboard']);

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      title: 'Login Page',
      authGuardPipe: redirectLoggedInToHome
    }
  },

  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      title: 'Home',
      data: { authGuardPipe: redirectUnauthorizedToLogin }
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'confirmed-booking',
       component: NewBookingsComponent
      },
      {
        path: 'add-booking',
       component: AddBookingsComponent
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/new-bookings/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dashboard/:bookingID',
        component: ViewBittingDetailsComponent
      },
      {
        path:'completed-trip-detail-page/:bookingID',
        component: CompleteTripDetailPageComponent
      },
      {
        path: 'active-trips',
        component: ActiveTripsComponent
      },
      {
        path: 'assigned-trips',
        component: AssginedTripsComponent
      },
      {
        path: 'completed-trips',
        component: CompletedTripsComponent
      },
      {
        path: 'drivers',
        component: DiversComponent,
      },
      {
        path: 'drivers/:driver',
        component: ViewDriverDetailsComponent
      },
      {
        path: 'tariff',
        component: TariffListComponent,
      },
      {
        path: 'tariff/:car',
        component: UpdateTariffComponent
      },
      {
        path: 'add-tariff',
        component: AddTariffComponent,
      },
      {
        path: 'drivers/edit/:itemCategory/:itemId',
        component: AddEditDriversComponent,
        data: { path: 'edit' }
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
