import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import firebase from 'firebase';
import * as moment from 'moment';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-completed-trips',
  templateUrl: './completed-trips.component.html',
  styleUrls: ['./completed-trips.component.scss']
})
export class CompletedTripsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  bookingHistory;
  showActiveTrips = false;

  objectKeys = Object.keys;
  displayedColumns = ['name', 'phoneNo', 'tripType', 'tripDetails', 'date', 'estimate', 'driverName', 'driverPhoneNo', 'driverTaxiNo', 'approveTrip'];

  constructor(private http: HttpClient, private router: Router) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() {

    firebase.database().ref('drivers').on("value", snap => {
      let data = snap.val();
      let bookings = [];
      console.log(data);

      if (data) {
        this.showActiveTrips = true;

        Object.keys(data).forEach(key => {
          if (data[key] && data[key].completedTrips) {
            Object.keys(data[key].completedTrips).forEach(item => {
              bookings.push(data[key].completedTrips[item]);
            });
          }
        });
        bookings.sort((a, b) => new Date(moment(a.bookedDate).toISOString()).getTime() - new Date(moment(b.bookedDate).toISOString()).getTime())
        this.bookingHistory = bookings.filter(item => item.isAssigned !== true);

        this.dataSource = new MatTableDataSource(bookings);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);

      } else {
        this.showActiveTrips = false;
      }
    })
  }

  viewDetail(booking) {
    console.log(booking);
    this.router.navigateByUrl(`completed-trip-detail-page/${booking.acceptedDriverID}-${booking.bookingId}`);
  }


}
