import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SITE_SETTINGS } from '../../settings';
import { FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import firebase from 'firebase';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent  {
  settings = SITE_SETTINGS;
  isAdmin = false;
  constructor(
    private router: Router,
  ) { 
  }

  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    if (this.settings.adminMobileNumber.includes(signInSuccessData.authResult.user.phoneNumber)) {
      this.isAdmin = true;
      this.router.navigateByUrl('/dashboard');
      return
    } else {
      alert(`You're Not Authorized to use this app`)
      firebase.auth().signOut();
    }
  }


 }
