import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import firebase from 'firebase';
import * as moment from 'moment';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-divers',
  templateUrl: './divers.component.html',
  styleUrls: ['./divers.component.scss']
})
export class DiversComponent implements OnInit {
  displayedColumns = ['name', 'phoneNumber', 'accountStatus', 'detail'];
  dataSource: MatTableDataSource<any>;
  allUsers;
  listAllDrivers;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  ngOnInit() {
    this.getUserData();
  }

  ngAfterViewInit() {

  }

  getUserData() {
    firebase.database().ref('drivers').on("value", snap => {

      let data = snap.val();
      let drivers = [];
      console.log(data);



      if (data) {
        Object.keys(data).forEach(key => {
          drivers.push(data[key])
        });
        console.log('drivers', drivers);
        this.dataSource = new MatTableDataSource(drivers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  viewDetails(row){
    this.router.navigateByUrl(`/drivers/${row.uid}`);
    console.log(row);
  }
}

