import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import firebase from 'firebase';

@Component({
  selector: 'app-tariff-list',
  templateUrl: './tariff-list.component.html',
  styleUrls: ['./tariff-list.component.scss']
})
export class TariffListComponent implements OnInit {
  displayedColumns = ['carType', 'oneway', 'roundtrip', 'onewayDriverFee', 'roundTripDriverFee', 'action'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  tariff;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.getTariff();
  }

  getTariff() {
    firebase.database().ref('tariff').on("value", snap => {
      let data = snap.val();
      let tariff = [];
      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key]) {
            data[key]['keyID'] = key;
            tariff.push(data[key]);
          }
        });
        this.tariff = tariff;
        console.log(this.tariff);
        this.dataSource = new MatTableDataSource(tariff);
      }
    });
  }

  viewTariffDetails(row) {
    console.log(row);
    this.router.navigateByUrl(`/tariff/${row.keyID}`);
    console.log(row);
  }

}
