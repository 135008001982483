import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-add-tariff',
  templateUrl: './add-tariff.component.html',
  styleUrls: ['./add-tariff.component.scss']
})
export class AddTariffComponent implements OnInit {
  tariffFormGroup: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
    this.tariffFormGroup = this.formBuilder.group({
      carType: ['', Validators.required],
      oneway: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      roundtrip: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      onewayDriverFee: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      roundTripDriverFee: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      availableCars: ['', Validators.required],
      passengers: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      luggage: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      image: ['', Validators.required],
      activeImage: ['', Validators.required],
      tariffImage: ['', Validators.required]
    });
  }


  get tariffValidation(): { [key: string]: AbstractControl } {
    return this.tariffFormGroup.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.tariffFormGroup.invalid) {
      return;
    }

    firebase.database().ref(`tariff`).push(this.tariffFormGroup.value).then(res => {
      this.toastr.success('Tariff Added Successfully');
      this.tariffFormGroup.reset();
      this.submitted = false;
    });
  }

}
