import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Data, Params } from '@angular/router';
import firebase from 'firebase';
import { environment } from '../../../../environments/environment';
import { SITE_SETTINGS } from '../../../settings';


@Component({
  selector: 'app-complete-trip-detail-page',
  templateUrl: './complete-trip-detail-page.component.html',
  styleUrls: ['./complete-trip-detail-page.component.scss']
})
export class CompleteTripDetailPageComponent implements OnInit {


  settings = SITE_SETTINGS;
  bookingID;
  bookings;
  bookingDetails;
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  ngOnInit(): void {

    this.bookingDetails = this.route.snapshot.params.bookingID;
    let bookingID= this.bookingDetails.split('-')[1];
    let uid = this.bookingDetails.split('-')[0];


    firebase.database().ref(`drivers/${uid}/completedTrips/${bookingID}`).on("value", snap => {
      let data = snap.val();
      if (data) {
        this.bookings = data;
        console.log(data);
      }
    })
  }


}
