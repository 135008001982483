import {Component} from '@angular/core';
import { SITE_SETTINGS } from '../../settings';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  settings = SITE_SETTINGS;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
