import { MapsAPILoader } from '@agm/core';
import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import firebase from 'firebase';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment.prod';
import { SITE_SETTINGS, TIME } from '../../settings';

@Component({
  selector: 'app-add-bookings',
  templateUrl: './add-bookings.component.html',
  styleUrls: ['./add-bookings.component.scss']
})
export class AddBookingsComponent implements OnInit {
  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  addBookingFormGroup: FormGroup;
  submitted = false;
  secondFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  showDirection = false;
  cars = [];
  selectedCar;
  origin;
  destination;
  requestRide;
  totalDistance;
  totalDuration;
  pickupPlace;
  dropPlace;
  journeyType = ['One Way', 'Round Trip'];
  selectedTripType = 'One Way';
  rideDetails;
  driverBata;
  baseFare;
  estimation;
  parseFloat: any;
  whatsupText;
  isBrowser: boolean;
  selectedDate;
  estimationDistance;
  bookingDetails;
  editable: boolean = true;
  settings = SITE_SETTINGS;
  minDate = new Date();
  tomorrow = new Date();
  originCityName;
  destinationCityName;
  routeDetails;
  selectedCarName;
  show = false;
  days;
  times = TIME

  constructor(private formBuilder: FormBuilder, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private location: LocationStrategy,
    private dateAdapter: DateAdapter<Date>,
    private toastr: ToastrService

  ) {
    this.dateAdapter.setLocale('en-GB');

  }

  ngOnInit(): void {

    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
    this.addBookingFormGroup = this.formBuilder.group({
      tripType: ['One Way', Validators.required],
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: ['', Validators.required],
      returnDate: [new Date],
      email: [''],
    });

    firebase.database().ref('tariff').on("value", snap => {
      let data = snap.val();
      let tariff = [];
      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key]) {
            data[key]['keyID'] = key;
            tariff.push(data[key]);
          }
        });

        this.cars.push(...tariff)
      }
    });

    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;
      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });



  }


  get addBookingFormGroupValidation(): { [key: string]: AbstractControl } {
    return this.addBookingFormGroup.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.addBookingFormGroup.invalid) {
      return;
    }

    firebase.database().ref(`tariff`).push(this.addBookingFormGroup.value).then(res => {
      // this.toastr.success('Tariff Added Successfully');
      this.addBookingFormGroup.reset();
      this.submitted = false;
    });
  }

  addMapEventListener(input, type) {

    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;


        if (this.origin && this.destination) {
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
          (this.selectedCar) ? this.requestRide = true : this.requestRide = false;
        }

        this.zoom = 12;
      });
    });
  }

  selectedCarType(ev) {
    this.selectedCar = ev;
    this.selectedCarName = ev.carType
  }

  onResponses(event) {

    this.routeDetails = {
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.originCityName,
      'destinationCityName': this.destinationCityName,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text
    }
  }

  requestAride() {

    this.submitted = true;

    if (this.addBookingFormGroup.get('tripType').value === 'Round Trip') {
      if (this.checkDate(this.addBookingFormGroup.get('pickupDate').value, this.addBookingFormGroup.get('returnDate').value)) {
        alert('Enter valid Return Date');
        return
      }
    }

    if (this.addBookingFormGroup.invalid) {
      return;
    }

    if (!this.origin || !this.destination) {
      alert('Enter valid Pickup or Drop Address');
      return
    }

    if (!this.selectedCar) {
      alert('Select preffered vechicle type');
      return
    }

    console.log(this.addBookingFormGroup.value);
    this.rideDetails = {
      ...this.addBookingFormGroup.value,
      ...this.routeDetails
    }

    this.getEstimation();
  }

  getEstimation() {


    if (this.rideDetails.tripType === 'One Way') {
      (this.rideDetails.totalDistance <= 130) ? this.rideDetails.totalDistance = 130 : this.rideDetails.totalDistance;
    } else {
      let startDate = moment(moment(this.rideDetails.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(this.rideDetails.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;

      (this.rideDetails.totalDistance * 2 <= 250) ? this.rideDetails.totalDistance = this.days * 250 : (this.rideDetails.totalDistance * 2 > 250 * this.days) ? this.rideDetails.totalDistance = this.rideDetails.totalDistance * 2 : this.rideDetails.totalDistance = 250 * this.days;
    }

    this.estimation = {
      tripEstimation:
        (this.rideDetails.tripType === 'One Way') ?
          this.rideDetails.totalDistance * this.selectedCar.oneway + this.selectedCar.onewayDriverFee :
          this.rideDetails.totalDistance * this.selectedCar.roundtrip + (this.selectedCar.roundTripDriverFee * this.days),
      ratePerKM: (this.rideDetails.tripType === 'One Way') ? this.selectedCar.oneway : this.selectedCar.roundtrip,
      driverBatta: (this.rideDetails.tripType === 'One Way') ? this.selectedCar.onewayDriverFee : this.selectedCar.roundTripDriverFee * this.days,
      baseFare: (this.rideDetails.tripType === 'One Way') ? this.rideDetails.totalDistance * this.selectedCar.oneway : this.rideDetails.totalDistance * this.selectedCar.roundtrip,
      tripType: this.rideDetails.tripType,
      carType: this.selectedCar.carType,
      distance: this.rideDetails.totalDistance
    }

    this.bookNow();

  }


  bookNow() {
    this.bookingDetails = {
      ...this.addBookingFormGroup.value,
      ...this.routeDetails,
      ...this.estimation,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }


    this.stepper.next();
    this.show = true;




  }

  confirm() {
    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'email': this.bookingDetails.email,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('DD/MM/YYYY'),
      'bookedDateISO': this.bookingDetails.pickupDate,
      'baseFare': this.bookingDetails.baseFare,
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('DD/MM/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `Confirmed Booking From ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail,
      'days': (this.bookingDetails.tripType === 'Round Trip') ? this.days : null,
      'sendCustomerEmail': true
    }

    if(this.settings.isPushNotificationAPIEnabled) {
      let notification = {
        title: `New One Way Trip Booking`,
        desc: `${bookingDetails.origin} to ${bookingDetails.destination}`
      }
      this.http.post(this.settings.pushNotificationAPI, notification ).subscribe(res => {console.log(res)});
    }


    firebase.database().ref(`confirmedBookings/${bookingDetails.bookingId}`).update(bookingDetails).then(res => {
      this.toastr.success('Booking Add Successfully');
      this.stepper.selectedIndex = 0;
      this.addBookingFormGroup.reset();
    });
  }



  checkDate(start, end) {

    let startDate = moment(start, 'DD-MM-YYYYTHH:mm:ss');
    let returnDate = moment(end, 'DD-MM-YYYYTHH:mm:ss');
    return startDate.isAfter(returnDate);
  }


}
