import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'App Control'
  },
  {
    name: 'New Bookings',
    url: '/dashboard',
    icon: 'cil-task'
  },
  {
    name: 'Confirmed Bookings',
    url: '/confirmed-booking',
    icon: 'cil-task'
  },
  {
    name: 'Active Trips',
    url: '/active-trips',
    icon: 'cil-bolt-circle'
  },
  {
    name: 'Assigned Trips',
    url: '/assigned-trips',
    icon: 'cil-address-book'
  },
  {
    name: 'Completed Trips',
    url: '/completed-trips',
    icon: 'cil-speedometer'
  },
  {
    name: 'Add Booking',
    url: '/add-booking',
    icon: 'cil-task'
  },
  {
    title: true,
    name: 'App Settings'
  },
  {
    name: 'Drivers',
    url: '/drivers',
    icon: 'cil-task'
  },
  {
    name: 'Website Settings',
    url: '/active-trips',
    icon: 'cil-bolt-circle'
  },
  {
    title: true,
    name: 'Tariff'
  },
  {
    name: 'Veiw Tatiff',
    url: '/tariff',
    icon: 'cil-task'
  },
  {
    name: 'Add New Tariff',
    url: '/add-tariff',
    icon: 'cil-task'
  }
];
