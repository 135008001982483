import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import firebase from 'firebase';
import * as moment from 'moment';
import { environment } from '../../../environments/environment.prod';


@Component({
  selector: 'app-new-bookings',
  templateUrl: './new-bookings.component.html',
  styleUrls: ['./new-bookings.component.scss']
})
export class NewBookingsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  bookingHistory;
  showTrips = false;
  objectKeys = Object.keys;
  displayedColumns = ['name', 'phoneNo', 'tripType', 'tripDetails','date','time', 'carType', 'estimate'];


  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() {
    firebase.database().ref('confirmedBookings').on("value", snap => {

      let data = snap.val();
      let bookings = [];

      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key]) {
              bookings.push(data[key]);
             
          }
        });

        bookings.sort((a, b) => new Date(moment(a.bookedDate).toISOString()).getTime() - new Date(moment(b.bookedDate).toISOString()).getTime())
       // bookings.reverse();

        this.dataSource = new MatTableDataSource(bookings);

        this.bookingHistory = bookings.filter(item => item.isAssigned !== true);

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 500);


        console.log(this.bookingHistory);
      }
    })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getRecord(row) {
    console.log(row);
  }

}
