import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariffListComponent } from './tariff-list/tariff-list.component';
import { UpdateTariffComponent } from './update-tariff/update-tariff.component';
import { AddTariffComponent } from './add-tariff/add-tariff.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';


@NgModule({
  declarations: [
    TariffListComponent,
    UpdateTariffComponent,
    AddTariffComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule
  ]
})
export class TariffModule { }
